var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"location"},[(_vm.currentMap === 'google')?_c('GmapMap',{ref:"mapRef",staticStyle:{"width":"100%","height":"100%"},attrs:{"map-type-id":"terrain","center":_vm.center,"zoom":15,"options":{
     zoomControl: true, // 启用缩放控件
        mapTypeControl: true, // 启用地图类型切换控件
        scaleControl: true, //  启用比例尺控件
        streetViewControl: true, // 启用街景
        rotateControl: true, // 启用旋转手势
        fullscreenControl: true, // 启用全屏
        disableDefaultUI: false // 禁用默认UI控件
    }}}):_vm._e(),_c('div',{staticClass:"mapSwitchBox"},[_c('div',{staticClass:"mapSwitch",on:{"click":function($event){return _vm.toggleMapMode()}}},[_c('img',{staticClass:"mapSwitchItem",attrs:{"src":"/mapGLB/2d-icon.png","alt":"2D"}})])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }