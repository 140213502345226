<template>
  <el-container>
    <!-- <el-header></el-header> -->
    <el-main>

      <div class="login_box">
        <el-form
          ref="loginFormRef"
          :model="loginForm"
          :rules="loginFormRules"
          label-width="10px"
          class="login_form"
        >
          <el-form-item >
            <el-radio-group v-model="loginForm.loginMode" @change="changeLoginMode">
              <el-row>
                <el-col :span="12">
                  <el-radio text-color="#36CC00" fill="#409EFF" label="userName">{{$t('login.loginMode.userName')}}</el-radio>
                </el-col>
                <!-- TODO 隐藏了设备号 -->
                <!-- <el-col :span="12" v-if="false">
                  <el-radio label="deviceImei">{{$t('login.loginMode.deviceImei')}}</el-radio>
                </el-col> -->
              </el-row>
            </el-radio-group>
            <!-- <el-tabs type="border-card" v-model="loginForm.loginMode" @change="changeLoginMode">
              <el-tab-pane label="userName">{{$t('login.loginMode.userName')}}</el-tab-pane>
              <el-tab-pane label="deviceImei">{{$t('login.loginMode.deviceImei')}}</el-tab-pane>
            </el-tabs> -->
          </el-form-item>
          <el-form-item prop="userName" >
            <el-input  v-model.trim="loginForm.userName" :prefix-icon="userIcon"></el-input>
          </el-form-item>
          <el-form-item  prop="password" >
            <el-input
            
              v-model.trim="loginForm.password"
              type="password"
              prefix-icon="iconfont icon-3702mima"
            ></el-input>
          </el-form-item>
          <el-form-item >
            <el-select v-model="loginForm.lang" size="small" @change="selectLang">
              <el-option v-for="item in langList" :key="item.key" :value="item.key" :label="item.label"
              > {{ item.label }} </el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="btns">
            <el-button type="primary" @click="login">{{$t('login.btn.login')}}</el-button>
            <el-button type="success" @click="experience">{{$t('login.btn.experience')}}</el-button>
          </el-form-item>
        </el-form>
      </div>




      <!-- 谷歌地图  -->
      <GmapMap
        ref="mapRef"
        map-type-id="terrain"
        :center="{lat:25.037798, lng:121.565170}"
        :zoom="12"
        v-if="currentMap === 'google'"
        style="width: 100%; height: 100%"
        :options="{
          zoomControl: true, // 启用缩放控件
          mapTypeControl: true, // 启用地图类型切换控件
          scaleControl: true, //  启用比例尺控件
          streetViewControl: true, // 启用街景
          rotateControl: true, // 启用旋转手势
          fullscreenControl: true, // 启用全屏
          disableDefaultUI: false // 禁用默认UI控件
        }"
      >
      </GmapMap>
      
      <!-- 高德地图  -->
      <!-- <AMapContainer class="baidu-map"  v-if="currentMap === 'baidu'"  /> -->

      <!-- 百度地图  v-if="false" v-if="currentMap === 'baidu'"  @ready="handleBaidu"  -->
      <baidu-map  ak="Xs1A6W2NSujM4c6y0Vdn2Uz60LpG6a0W" id="baiduMap" class="baidu-map"   v-if="currentMap === 'baidu'"
                 :scroll-wheel-zoom='true'>
        <bm-navigation anchor="BMAP_ANCHOR_BOTTOM_LEFT"/>
        <bm-map-type :map-types="['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP']" anchor="BMAP_ANCHOR_TOP_LEFT"/>
      </baidu-map>


    </el-main>
    <el-footer>
        <!-- © 2024-2025  -->
    </el-footer>
  </el-container>
</template>

<script>
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import BmNavigation from 'vue-baidu-map/components/controls/Navigation.vue';
import BmMapType from 'vue-baidu-map/components/controls/MapType.vue';


import { deviceLogin, experienceLogin, login } from '@/api/sysUser'
import { guid } from '@/api/cookie'
import { checkLogin } from '@/api/globalProcessErr'
// import { listAllGpsDevice } from '@/api/gpsDevice'
import {
  // batchMakeMarker,
  initMap,
  // moveFirstPoint
} from '@/api/map'
// import { deviceStatusFormat, getObjString, convertDateTimeToString } from '@/api/util'

// 高德地图
// import AMapContainer from '@/components/amap/AMapContainer.vue'

// import Mapview from "./components/MapContainer.vue" // 地图组件

export default {
  components: {
    BaiduMap,
    BmNavigation,
    BmMapType,
  //   // AMapContainer,
  //   // Mapview
    },
  data () {
   
    return {
      // 地图数据点
      loginForm: {
        userName: '',
        password: '',
        loginMode: 'userName',
        lang: ''
      },
      langList: [
        { key: 'zh-TW', label: '中文繁體' },
        { key: 'zh-CN', label: '中文简体' },
        { key: 'en-US', label: 'English' }
      ],
      center: {
        lat: 22.78,
        lng: 114
      },
      deviceList: [],
      devicePoints: [],
      deviceMarks: [],
      infoWindowList: [],
      mapInstance: undefined,
      openedDevice: undefined,
      userIcon: 'iconfont icon-user',
      currentMap: 'baidu'
    }
  },
  computed: {
    loginFormRules () {
      return {
        userName: [
          { required: true, message: this.$t('login.msg.rules.userName.required'), trigger: 'blur' },
          { min: 1, max: 30, message: this.$t('login.msg.rules.userName.required'), trigger: 'blur' }
        ],
        password: [
          { required: true, message: this.$t('login.msg.rules.password.required'), trigger: 'blur' },
          { min: 1, max: 30, message: this.$t('login.msg.rules.password.required'), trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.checkLang()
    // this.autoLogin()
  },
  mounted () {
    // TODO 这里需要判断是否登录，如果登录了则跳转到首页
    sessionStorage.removeItem('userName')
    sessionStorage.removeItem('token')
    sessionStorage.removeItem('userId')
    const _this = this
    setTimeout(function () {
      _this.initMapPage()
      // _this.$nextTick(() => {
      //   _this.markAllDevice()
      //   // _this.experience()
      // })
    }, 800)
  },
  methods: {
    checkLang () {
      let lang = 'en-US'
      if (window.navigator.language) {
        lang = window.navigator.language
      } else {
        lang = window.navigator.browserLanguage
      }

      if (lang === 'en-US') {
        this.center = {
          lat:25.037798, 
          lng:121.565170
        }
        this.currentMap = 'google'
      } else if (lang === 'zh-CN') {
        this.center = {
          lat: 22.78,
          lng: 114
        }
        this.currentMap = 'baidu'
      } else {
        lang = 'zh-TW'
        this.center = {
          lat:25.037798, 
          lng:121.565170
        }
        this.currentMap = 'google'
      }
      this.loginForm.lang = lang
      window.sessionStorage.setItem('lang', lang)
      this.$i18n.locale = lang
    },
    changeLoginMode () {
      if (this.loginForm.loginMode === 'userName') {
        this.userIcon = 'iconfont icon-user'
      } else {
        this.userIcon = 'el-icon-map-location'
      }
    },
    selectLang () {
      let lang = this.loginForm.lang
      if (lang === 'en-US') {
        this.center = {
          lat:25.037798, 
          lng:121.565170
        }
        this.currentMap = 'google'
      } else if (lang === 'zh-CN') {
        this.center = {
          lat: 22.78,
          lng: 114
        }
        this.currentMap = 'baidu'
      } else {
        this.center = {
          lat:25.037798, 
          lng:121.565170
        }
        lang = 'zh-TW'
        this.currentMap = 'google'
      }

      window.sessionStorage.setItem('lang', lang)
      this.$i18n.locale = lang
      // this.mapInstance = undefined
      const self = this
      this.$nextTick(() => {
        self.initMapPage()
        setTimeout(function () {
          // self.markAllDevice()
        }, 800)
      })
    },
    listAllDevice () {
      // let self = this
      // listAllGpsDevice().then(res => {
      //   checkLogin(res)
      //   if (res.data.code !== 0) {
      //     return self.$message.error(res.data.msg)
      //   }
      //   self.deviceList = res.data.data
      //   if (self.deviceList.length > 0) {
      //     self.center.lat = self.deviceList[0].lastLatitude
      //     self.center.lng = self.deviceList[0].lastLongitude
      //   }
      //   self.initMapPage()
      // }).catch(err => {
      //   return self.$message.error(err.message)
      // })
    },
    // 地图组件渲染完毕时触发
    handleBaidu ({ BMap, map }) {
      this.mapInstance = {
        mapType: 'baidu',
        map: map,
        bMap: BMap,
        markList: [],
        currentOpenInfoWindow: undefined,
        contentFunc: this.makeContent,
        haveBtn: false
      }
    },
    makeContent (data, haveBtn) {
      const self = this
      // const motionStatus = self.motionStatusFormatter(data)
      return function () {
        let item = 'engine'
        let content = '<style> .deviceInfo { font-size: 12px; margin: 0; padding: 0;} ul li { display: flex; flex-flow: row nowrap; justify-content: center; align-items: center; } ul li div { width: 160px; } .centerDiv{ width: 100%; display: flex; justify-content: space-around; margin-top: 4px; } .centerDiv button { font-size: 12px; width: 60px; border-radius: 10px;} </style>'
        content += '<ul class="deviceInfo">'
        content += '<li> <div> <b>' + self.$t('device.deviceImei') + ':</b> <span>' + data.deviceImei + '</span> </div>'
        content += '<div> <b>' + self.$t('device.deviceName') + ':</b> <span>' + data.deviceName + '</span> </div> </li>'
        content += '<li> <div> <b>' + self.$t('device.lastLatitude') + ':</b> <span>' + getObjString(data.lastLatitude) + '</span> </div>'
        content += '<div> <b>' + self.$t('device.lastLongitude') + ':</b> <span>' + getObjString(data.lastLongitude) + '</span> </div> </li>'
        content += '<li> <div> <b>' + self.$t('device.accStatus') + ':</b> <span>' + deviceStatusFormat(data, item) + '</span> </div>'
        content += '<div> <b>' + self.$t('device.lastLocationTime') + ':</b> <span>' + convertDateTimeToString() + '</span> </div> </li>'
        content += '<li> <div> <b>' + self.$t('device.lastSpeed') + ':</b> <span>' + getObjString(data.lastSpeed) + '</span> </div>'
        content += '<div> <b>' + self.$t('device.heading') + ':</b> <span>' + getObjString(data.heading) + '</span> </div> </li>'
        content += '<li> <div> <b>' + self.$t('device.odometer') + ':</b> <span>' + getObjString(data.odometer) + '</span> </div>'
        content += '<div> <b>' + self.$t('device.fuel') + ':</b> <span>' + getObjString(data.fuel) + '</span> </div> </li>'
        content += '<li> <div> <b>' + self.$t('device.temperature') + ':</b> <span></span> </div>'
        content += '<div> <b>' + self.$t('device.address') + ':</b> <span></span> </div> </li>'
        if (haveBtn) {
          content += '<li> <div class="centerDiv">'
          content += '<button onclick="realTimeTrack(\'' + data.deviceImei + '\')"" type="button"> ' + self.$t('devicePage.realTimeTrack') + ' </button>'
          content += '<button onclick="historicalTrack(\'' + data.deviceImei + '\')" type="button">  ' + self.$t('devicePage.historicalTrack') + '  </button>'
          content += '<button onclick="sendCommand(\'' + data.deviceImei + '\')" type="button">  ' + self.$t('devicePage.sendCommand') + '  </button>'
          content += '<button onclick="getDeviceInfo(\'' + data.deviceImei + '\')" type="button">  ' + self.$t('devicePage.getDeviceInfo') + ' </button>'
          content += '</div> </li>'
        }
        content += '</ul>'
        return content
      }
    },
    motionStatusFormatter (row, column) {
      if (row.lastMotionStatus === 1) {
        return 'motion'
      } else if (row.lastMotionStatus === 0) {
        return 'static'
      } else {
        return ''
      }
    },
    autoLogin () {
      const session = guid()
      window.sessionStorage.setItem('session', session)
      const self = this
      experienceLogin().then(res => {
        checkLogin(res)
        if (res.data.code !== 0) {
          return self.$message.error(res.data.msg)
        }
        window.sessionStorage.setItem('token', res.data.data.token)
        window.sessionStorage.setItem('userId', res.data.data.userId)
        window.sessionStorage.setItem('userName', res.data.data.userName)
        window.sessionStorage.setItem('selfCustomerId', res.data.data.customerId)
        window.sessionStorage.setItem('selfDeptId', res.data.data.deptId)
        self.$store.commit('setUserInfo', res.data.data)
        self.$store.commit('setToken', res.data.data.token)
        self.listAllDevice()
      }).catch(err => {
        return self.$message.error(err.message)
      })
    },
    markAllDevice () {
      this.mapInstance = batchMakeMarker(this.mapInstance, this.deviceList)
    },
    initMapPage () {
      const self = this

      if (self.currentMap === 'google' && window.google && window.google.maps) {
        if (self.mapInstance === undefined || self.mapInstance.mapType !== 'google') {
          self.mapInstance = {
            mapType: 'google',
            map: self.$refs.mapRef.$mapObject,
            mapRef: self.$refs.mapRef,
            googleMaps: window.google.maps,
            markList: [],
            currentOpenInfoWindow: undefined,
            contentFunc: self.makeContent,
            haveBtn: false,
           
          }
        }
      } else {
        if (self.mapInstance === undefined || self.mapInstance.mapType !== 'baidu') {
          self.mapInstance = {
            mapType: 'baidu',
            map: window.$BAIDU$._instances.TANGRAM__1,
            bMap: window.BMap,
            markList: [],
            currentOpenInfoWindow: undefined,
            // contentFunc: self.makeContent,
            haveBtn: true
          }
        }
      }
      // 异步执行 
      
      initMap(self.mapInstance, self.center, 13)
    },
    experience () {
     
      const session = guid()
      window.sessionStorage.setItem('session', session)
      const self = this
      experienceLogin().then(res => {
        checkLogin(res)
        if (res.data.code !== 0) {
          return self.$message.error(res.data.msg)
        }
        // console.log("登录",res)
        window.sessionStorage.setItem('token', res.data.data[0].token)
        window.sessionStorage.setItem('userId', res.data.data[0].userId)
        window.sessionStorage.setItem('userName', res.data.data[0].userName)
        window.sessionStorage.setItem('selfCustomerId', res.data.data[0].customerId)
        window.sessionStorage.setItem('selfDeptId', res.data.data[0].deptId)
        window.sessionStorage.setItem('pwdType', res.data.pwdType)
        self.$store.commit('setUserInfo', res.data.data[0])
        self.$store.commit('setToken', res.data.data[0].token)
       
        window.sessionStorage.setItem('historicalTrackDeviceImei',"赛事名称")
      window.sessionStorage.setItem('historicalTrackStartTime', "2023-06-20 08:30:00")
      window.sessionStorage.setItem('historicalTrackEndTime',"2023-06-20 12:30:00")
     
       window.sessionStorage.setItem("historicalTrackStarLat", 24.14914 );
       window.sessionStorage.setItem("historicalTrackStarLon", 120.68117);

       window.sessionStorage.setItem("historicalTrackEndLon", 120.42185330498783);
       window.sessionStorage.setItem("historicalTrackEndLat", 23.358101743572295);

       if (sessionStorage.getItem('token') && sessionStorage.getItem('userId')) {
        return this.$router.push({
          path: '/home'
        })
      }

        // self.$router.push('/home')
        // listAllGpsDevice().then(res => {
        //   if (res.data.code === 0 && res.data.data && res.data.data.length > 0) {
        //     self.deviceList = res.data.data
        //     self.markAllDevice()
        //     moveFirstPoint(self.mapInstance)
        //   }
        // }).catch(e => {
        //   return self.$message.error(e.message)
        // })
      }).catch(err => {
        return self.$message.error(err.message)
      })
    },
    login () {
      // 表单预验证
      // valid: bool类型
      const self = this
      this.$refs.loginFormRef.validate(async valid => {
        // console.log(valid)
        if (!valid) return false
        // this.$http.post('login', this.loginForm): 返回值为promise
        // 返回值为promise,可加await简化操作 相应的也要加async
        // const { data: res } = await this.$http.post('user/login', this.loginForm)
        const session = guid()
        window.sessionStorage.setItem('session', session)

        if (this.loginForm.loginMode === 'userName') {
          login(self.loginForm).then(res => {
            // console.log(res)
            if (res.data.code !== 0) return this.$message.error(res.data.msg)
            this.$message.success(self.$t('login.msg.login.success'))
            // 1、将登陆成功之后的token, 保存到客户端的sessionStorage中; localStorage中是持久化的保存
            //   1.1 项目中出现了登录之外的其他API接口，必须在登陆之后才能访问
            //   1.2 token 只应在当前网站打开期间生效，所以将token保存在sessionStorage中
            window.sessionStorage.setItem('token', res.data.data[0].token)
            window.sessionStorage.setItem('userId', res.data.data[0].userId)
            window.sessionStorage.setItem('userName', res.data.data[0].userName)
            window.sessionStorage.setItem('pwdType', res.data.pwdType)

            // window.sessionStorage.setItem('selfCustomerId', res.data.data.customerId)
            // window.sessionStorage.setItem('selfDeptId', res.data.data.deptId)
            self.$store.commit('setUserInfo', res.data.data[0])
            self.$store.commit('setToken', res.data.data[0].token)
            // self.$store.commit('setLang', self.loginForm.lang)
            // window.sessionStorage.setItem('lang', self.loginForm.lang)
            // 2、通过编程式导航跳转到后台主页, 路由地址为: /home
            self.$router.push('/competition/competitionList')
          })
        } else {
          const req = {
            deviceImei: self.loginForm.userName,
            password: self.loginForm.password
          }
          deviceLogin(req).then(res => {
            if (res.data.code !== 0) return this.$message.error(res.data.msg)
            this.$message.success(self.$t('login.msg.login.success'))
            // 1、将登陆成功之后的token, 保存到客户端的sessionStorage中; localStorage中是持久化的保存
            //   1.1 项目中出现了登录之外的其他API接口，必须在登陆之后才能访问
            //   1.2 token 只应在当前网站打开期间生效，所以将token保存在sessionStorage中
            window.sessionStorage.setItem('token', 'emptyToken')
            window.sessionStorage.setItem('userId', res.data.data[0][0].deviceId)
            window.sessionStorage.setItem('userName', res.data.data[0][0].deviceImei)
            window.sessionStorage.setItem('selfCustomerId', res.data.data[0][0].customerId)
            window.sessionStorage.setItem('selfDeptId', res.data.data[0][0].deptId)
            window.sessionStorage.setItem('device', JSON.stringify(res.data.data[0][0]))
            window.sessionStorage.setItem('pwdType', res.data.pwdType)
            self.$store.commit('setUserInfo', res.data.data[0][0])
            self.$store.commit('setToken', 'emptyToken')
            // self.$store.commit('setLang', self.loginForm.lang)
            // window.sessionStorage.setItem('lang', self.loginForm.lang)
            // 2、通过编程式导航跳转到后台主页, 路由地址为: /home
            self.$router.push('/home')
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
  * {
    margin: 0;
    padding: 0;
  }
.el-container {
  height: 100% !important;
}

.el-header {
  background: #1a7582;
  height: 8% !important;

}

.el-main {
  height: 84% !important;
  padding: 0 0 0 0;
}

.el-footer {
  background: #052531;
  height: 4% !important;
}

.login_container {
  background: url(../assets/1920x900.png) no-repeat;
  background-size: 100%;
  //border-bottom: 1px solid #000000;
  //background-color: #dae9fe;
  // position: relative;
  height: 100%;
}

.login_box {
z-index: 999;
  width: 300px;
  height: 280px;
 
  border-radius: 10px;
  position: absolute;
  right: 0;
  top: 8%;
  // border-top: 1px solid green;
  padding: 5px !important;
  transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
  background-color: #052531;

  .el-form {
    padding: 0 0 0 0 !important;
    margin-top: 0!important;
    margin-bottom: 0!important;
    .el-radio-group {
      padding: 0 0 0 0 !important;
    }
    .el-form-item {
      margin: 10px !important;
      padding: 0 0 0 0 !important;
    }
  }
}

/* 媒体查询：针对小屏幕设备（如手机）的样式 */  
// @media (max-width: 600px) {  
//   .login_box {  
//     width: 250px; /* 或者调整为适合手机屏幕的固定宽度 */  
//     position: absolute;
//     right: 0;
//     top: 12%;
//     // border-top: 1px solid green;
//     padding: 10px 10px 10px 10px !important;
//     transform: translate(-20%, 0);
//     -webkit-transform: translate(250%, 0); // -150%
//     background-color: #052531;
//   }  
// }

/* 媒体查询，当屏幕宽度小于768px时（一般认为是手机或平板的横屏模式），应用以下样式 */
/* 针对手机屏幕的样式 */
// @media (max-width: 600px) {
//     .login_box {
//         width: 90%; /* 使宽度适应手机屏幕 */
//         max-width: 320px; /* 设定一个最大宽度，防止在大屏幕手机上过大 */
//         height: auto; /* 可以根据需要调整高度，或者保持固定值 */
//         position: relative; /* 移除绝对定位 */
//          top: auto;
//         top: auto;
//         transform: none; /* 移除 transform */
//         -webkit-transform: none;
//         margin: 0 auto; /* 使用 margin auto 来水平居中 */
//         background-color: #052531; /* 这里我将背景色设置为深色，如果需要可以调整 */
//     }
// }
/* 针对小屏幕的样式（屏幕宽度小于或等于768像素） */
@media (max-width: 600px) {
    .login_box {
        width: 100%; /* 使宽度完全适应屏幕 */
        max-width: 320px; /* 在非常小的屏幕上设置一个最大宽度 */
        margin: 0 auto; /* 水平居中 */
        /* 如果需要，可以调整其他样式以适应小屏幕 */
        background-color: #052531; /* 更改为适合小屏幕的深色背景 */
        /* 其他小屏幕样式... */
        left: 50%;
        top: 8%;
       
    }
}

.login_form {
  position: absolute;
  //top: 5%;
  // left: -5.5%;
  width: 90%;
  padding: 5px 5px 5px 5px;
  box-sizing: border-box;
}

.el-button {
  //padding-left: 5px;
  //padding-right: 3px;
  padding: 10px 15px 10px 15px;
}

.btns {
  display: flex;
  justify-content: center;
}

.el-radio {
  color: #ffffff;
  font-weight: 700;
  margin-right: 15px;
}

.info {
  font-size: 13px;
  margin: 10px 15px;
}

.baidu-map {
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  margin: 5px 5px 5px 5px;
}
</style>
