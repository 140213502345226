<template>
  <div class="location">

        <!-- 输入框 -->
        <!-- <div class="input_style">
      <div style="width: 300px;">
        <el-button type="success" @click="clearPath">清除轨迹线</el-button>
      </div>
      <div>


       
      </div>
    </div> -->


    <GmapMap ref="mapRef" v-if="currentMap === 'google'" map-type-id="terrain"
      :center="center" :zoom="15" style="width: 100%; height: 100%" :options="{
       zoomControl: true, // 启用缩放控件
          mapTypeControl: true, // 启用地图类型切换控件
          scaleControl: true, //  启用比例尺控件
          streetViewControl: true, // 启用街景
          rotateControl: true, // 启用旋转手势
          fullscreenControl: true, // 启用全屏
          disableDefaultUI: false // 禁用默认UI控件
      }">
    </GmapMap>

            <!-- 地图切换按钮-->
            <div class="mapSwitchBox">
              <div class="mapSwitch" @click="toggleMapMode()">
                <img class="mapSwitchItem" src="/mapGLB/2d-icon.png" alt="2D">
            </div>


          </div>
  </div>
</template>


<script>
import { deviceStatusFormat, getObjString, convertDateTimeToString } from '@/api/util'
import { initMap,moveToCenter,
  batchMakePathsAndMarkers, drawStartEndMarkersAndPath,
  makePath, makeColorPath, makeTrackMarker,cleanAllPath } from '@/api/map';
// import MyDataSource from '@/assets/data/googledata1.json'; // 一条的数据
import MyDataSource from '@/assets/data/pigeon_5.json'; // 多条轨迹的数据 数据格式是 [[{},{}],[{},{}],[{},]]

// 引入ccs 适应手机屏幕 消除原有样式
import '@/assets/css/mywindow.css';

export default {
  data() {
    return {
      map: null,
      currentMap: 'google', // 默认显示谷歌地图
      mapInstance: undefined,
      center: {
        lat: 25.037798,
        lng: 121.565170
      }, // 地图中心点坐标
      markers: [], // 标记点数组
      pigeonData: [], // 轨迹数据
      textarea: '',
      hasCollapse: false,
      userLocation: null, // 用户位置
       totalDistance :0, // 累计里程
        totalDuration : 0,  // 累计时长
        averageSpeed:0, // 平均速度
        pointIndex: 0, // 当前轨迹点索引
        //比赛起点对象 经度纬度
        startPoint: {
          lat: undefined,
          lng: undefined
        },
        //比赛终点对象 经度纬度
        endPoint: {
          lat: undefined,
          lng:undefined
        }
    }
  },
  created() {

    this.checkLang();
    // 获取起点位置 结束位置
  
   

    // TODO 测试获取轨迹数据
    const sessionPigeonData = window.sessionStorage.getItem('pigeonTrajectoryData') || [];
    if (sessionPigeonData.length > 0) {
      const data = JSON.parse(sessionPigeonData);
      this.pigeonData = data;
      // this.list = pigeonData;

      console.log("this.data", data);
    } else {
      //
      console.log("测试获取轨迹数据-->", sessionPigeonData);
    }

  },
  mounted() {
    this.startPoint = {
      lat: window.sessionStorage.getItem('historicalTrackStarLat')-0 || 0,
      lng: window.sessionStorage.getItem('historicalTrackStarLon')-0 || 0,
    }
    this.endPoint = {
      lat: window.sessionStorage.getItem('historicalTrackEndLat')-0 || 1,
      lng: window.sessionStorage.getItem('historicalTrackEndLon')-0 || 1,
    }

    let _this = this;
    // 可以在这里添加地图加载完成后的其他操作
    setTimeout(function () { // 延迟加载地图，防止页面抖动问题

      _this.initMapPage()
      // moveToCenter(_this.mapInstance, _this.center)
      // makePath(_this.mapInstance, MyDataSource)
      // makeColorPath(_this.mapInstance, MyDataSource, '#FF0000')
      console.log("this.pigeonData", _this.pigeonData);
      if (_this.pigeonData && _this.pigeonData.length > 0) {
         // 设置地图中心点
        moveToCenter(_this.mapInstance, _this.pigeonData[0][0])

        // makeBaiduTrackMarker
        //  const latitude = track.lat;  const longitude = track.lng
        // makeTrackMarker(_this.mapInstance,  _this.pigeonData[0][0],"start")
     
        batchMakePathsAndMarkers(_this.mapInstance, _this.pigeonData)
// makeTrackMarker

        drawStartEndMarkersAndPath(_this.mapInstance, _this.startPoint, _this.endPoint)
        // 假设 mapInstance 是地图实例，startPoint 和 endPoint 是起点和终点的经纬度对象
// const startPoint = { lat: 22.571, lng: 113.800 };
// const endPoint = { lat: 25.07798, lng: 121.55170 };

// const { startMarker, endMarker } = drawStartEndMarkersAndPath(_this.mapInstance, startPoint, endPoint);
       
      }
    }, 800)
  },
  beforeDestroy() {
  },

  methods: {
    checkLang(){
              // 获取当前系统语言
              const language = navigator.language || navigator.userLanguage;
        // 判断语言
        let systemLanguage;
        if (language.startsWith('zh')) {
            // 判断是简体中文还是繁体中文
            if (language === 'zh-CN' || language === 'zh-Hans' || language === 'zh') {
                systemLanguage = 'zh-CN';
            } else if (language === 'zh-TW' || language === 'zh-Hant') {
                systemLanguage = 'zh-TW';
            } else {
                systemLanguage = 'en-US';
            }
        } else if (language.startsWith('en')) {
            systemLanguage = 'en-US';
        } else {
            systemLanguage = 'en-US';
        }

        // console.log('系统语言是:', systemLanguage);

        // 打印当前系统语言
        // console.log("打印当前系统语言language", language);
        window.sessionStorage.setItem('lang', systemLanguage);

        // 从 sessionStorage 获取语言设置，如果没有则默认为 'zh-CN'  'en-US'    'zh-TW'
        const lang = window.sessionStorage.getItem('lang');

        // 设置 Vue I18n 的当前语言
        this.$i18n.locale = lang;

        // 打印当前语言
        console.log("2D Vue I18n 的当前语言", this.$i18n.locale);
    },

    toggleMapMode() {
            // 改成跳转页面
            // this.$router.push('/pigeonRacingPlatform2D')
            // 返回上一个页面
            this.$router.go(-1)
        },

    clearPath(){
      cleanAllPath(this.mapInstance)
    },
    initMapPage() {
      const self = this;
      if (self.currentMap === 'google' && window.google && window.google.maps) {
        if (this.mapInstance === undefined) {
          this.mapInstance = {
            mapType: 'google',
            map: this.$refs.mapRef.$mapObject,
            mapRef: this.$refs.mapRef,
            googleMaps: window.google.maps,
            markList: [],
            polyList: [],
            currentOpenInfoWindow: undefined,
            contentFunc: this.makeInfoWindowConetnt,
            haveBtn: true
          }
        }
        // 异步执行初始化地图
        initMap(self.mapInstance, self.center, 13);
        console.log('sadf');
      } else {
        console.error('谷歌地图API未加载或当前地图类型不是谷歌地图');
        // 可以在这里添加错误处理逻辑，例如显示错误提示
      }
    },

    makeInfoWindowConetnt(device, haveBtn) {
      const self = this
      return function () {
        let content = '<style>'
        content += '.deviceInfo { font-size: 12px; margin: 0; padding: 0;}'
        content += 'ul li { display: flex; flex-flow: row nowrap; justify-content: center; align-items: center; }'
        content += 'ul li div { width: 200px; }'
        content += '.centerDiv{ width: 100%; display: flex; justify-content: space-around; margin-top: 4px; }'
        content += '.centerDiv button { font-size: 12px; width: 60px; border-radius: 10px; color: white; }'
        content += '.buluButton { border: 1px solid #00A2E9; background-color: rgba(0, 162, 240 ,0.5); }'
        content += '.orangeButton { border: 1px solid chocolate; background-color: rgba(210, 105, 30, 0.5); } '
        content += '.redButton { border: 1px solid red; background-color: rgba(233, 10, 10, 0.5); }'
        content += '.purpleButton { border: 1px solid purple; background-color: rgba(128, 0, 128, 0.5); }'
        content += '.yellowButton { border: 1px solid #F0AC20; background-color: rgba(242, 120, 32, 0.5); }'
        content += '</style>'
        content += '<ul class="deviceInfo">'
        if (device.deviceType !== 2) {
          let speed = 0
          if (device.accumulateOdometer && device.accumulateDuration && device.accumulateDuration !== 0) {
            speed = device.accumulateOdometer / device.accumulateDuration
          }

          self.totalDistance += device.accumulateOdometer; // 这可能是从起点到这里的累计里程
          self.totalDuration += device.accumulateDuration; // 这可能是从起点到这里的累计时长（单位可能是秒）
          self.averageSpeed = self.totalDistance  / self.totalDuration 

          content += '<li> <div> <b>' + self.$t('device.deviceImei') + ':</b> <span>' + device.deviceImei + '</span> </div>'
          content += '<div> <b>' + self.$t('device.nickname') + ':</b> <span>' + device.deviceName + '</span> </div> </li>'
          content += '<li> <div> <b>' + self.$t('device.lastDeviceVol') + ':</b> <span>' + (device.lastDeviceVol || 0) + '%' + '</span> </div>'
          content += '<div> <b>' + self.$t('device.version') + ':</b> <span>' + (device.version || '') + '</span> </div> </li>'
          content += '<li> <div> <b>' + self.$t('device.positioningTime') + ':</b> <span>' + device.gpsTime + '</span> </div>'
          content += '<div> <b>' + self.$t('device.uploadTime') + ':</b> <span>' + device.lastLocationTime + '</span> </div> </li>'
          content += '<li> <div> <b>' + self.$t('device.lastLatitude') + ':</b> <span>' + device.lat + '</span> </div>'
          content += '<div> <b>' + self.$t('device.lastLongitude') + ':</b> <span>' + device.lng + '</span> </div> </li>'
          // content += '<li> <div> <b>' + self.$t('device.weather') + ':</b> <span>' + device.weather + '</span> </div>'
          // content += '<div> <b>' + self.$t('device.lastPositionDesc') + ':</b> <span>' + device.lastPositionDesc + '</span> </div> </li>'
          content += '<li> <div> <b>' + self.$t('device.distanceFromPreviousPoint') + ':</b> <span>' + (device.accumulateOdometer || 0) + self.$t('unit.meter') + '</span> </div>'
          content += '<div> <b>' + self.$t('device.timeFromPreviousPoint') + ':</b> <span>' + (device.accumulateDuration || 0) + self.$t('unit.second') + '</span> </div> </li>'
          content += '<li> <div> <b>' + self.$t('unit.speedPerMinute') + ':</b> <span>' + (speed * 60).toFixed(4) + self.$t('unit.meterPerMinute') + '</span> </div>'
          content += '<div> <b>' + self.$t('unit.speedPerHour') + ':</b> <span>' + (speed * 3.6).toFixed(4) + self.$t('unit.kilometerPerHour') + '</span> </div> </li>'
          content += '<li> <div> <b>' + self.$t('unit.averageSpeedPerMinute') + ':</b> <span>' + (self.averageSpeed * 60).toFixed(4) + self.$t('unit.meterPerMinute') + '</span> </div>'
          content += '<div> <b>' + self.$t('unit.averageSpeedPerHour') + ':</b> <span>' + (self.averageSpeed * 3.6).toFixed(4) + self.$t('unit.kilometerPerHour') + '</span> </div> </li>'
          content += '<li> <div> <b>' + self.$t('device.altitude') + ':</b> <span>' + (device.altitude || 0) + self.$t('unit.meter') + '</span> </div>'
          content += '<div> <b>' + self.$t('device.locationPoints') + ':</b> <span>' + (device.pointIndex || '') + '</span> </div> </li>'
        }
        if (haveBtn) {
          content += '<li> <div class="centerDiv">'
          content += '<button class="buluButton" onclick="realTimeTrack(\'' + device.deviceImei + '\')"" type="button"> ' + self.$t('devicePage.realTimeTrack') + ' </button>'
          content += '<button class="orangeButton" onclick="historicalTrack(\'' + device.deviceImei + '\')" type="button">  ' + self.$t('devicePage.historicalTrack') + '  </button>'
          content += '<button class="redButton" onclick="flightTraining(\'' + device.deviceImei + '\')" type="button">  ' + self.$t('devicePage.flightTraining') + '  </button>'
          // content += '<button onclick="sendCommand(\'' + device.deviceImei + '\')" type="button">  ' + self.$t('devicePage.sendCommand') + '  </button>'
          content += '<button class="purpleButton" onclick="modifyConfiguration(\'' + device.deviceImei + '\')" type="button">  ' + self.$t('devicePage.config') + '  </button>'
          content += '<button class="yellowButton" onclick="getDeviceInfo(\'' + device.deviceImei + '\')" type="button">  ' + self.$t('devicePage.getDeviceInfo') + ' </button>'
          content += '</div> </li>'
        }
        content += '</ul>'
        return content
      }
    },
    getRandomColor() { //获取随机颜色
      var color = '#'
      var randomArr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f']
      for (let i = 0; i < 6; i++) {
        color += randomArr[parseInt(Math.random() * 15)]
      }
      return color
    },
  },


}
</script>

<style lang="less" scoped>
.location {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.mapContainer.collapseList {
  width: 100%;
}

.mapContainer {
  height: 100%;
  width: calc(100%);
  float: left;
}

.collapseImg.collapseList {
  left: 0;
}

.deviceList.collapseList {
  display: none;
}

.input_style {
  z-index: 8;
  position: absolute;
  display: flex;
}


// 地图切换
.mapSwitchBox {
    position: absolute;
    right: 16px;
    bottom: 50%;
    z-index: 100;
}

// 地图按钮
.mapSwitch {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 36px;
    height: 36px;
    border-radius: 20px;
    background-color: hsla(0, 0%, 100%, .6666666666666666);
    padding: 2px 2px 2px 4px;
    margin-top: 10px;
}

// 比赛数据按钮
.mapGameBox {
    position: absolute;
    left: 16px;
    top: 15px;
    z-index: 100;
}

.mapSwitchItem {
    width: 28px;
    height: 28px;
}

</style>
